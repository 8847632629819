/* eslint-disable no-nested-ternary */
const Tooltip = ({
  children, className1 = '',
  className2 = 'p-2 font-medium text-xs leading-2.5 rounded-lg text-white shadow-comment-overlay bg-ToolTip backdrop-filter w-max',
  type,
  directions,
  arrowDirections,
  alignment,
  alignmentArrow
}) => {
  const direction = {
    top: `bottom-full ${alignment} m-auto mb-4`,
    right: 'left-full ml-4 top-1/2 transform -translate-y-1/2',
    bottom: `${type === 'poll' ? 'top-1 -left-36' : type === 'zeroPercentage' ? 'top-1 left-4' : type === 'Delete' ? 'top-8 -left-5' : (type === 'Duplicate' || type === 'Preview') ? 'top-8 -left-5' : type === 'Edit' ? 'top-8 -left-2' : 'top-28 left-0'} right-0  m-auto ${alignment}`,
    left: 'right-full mr-4 top-1/2 transform -translate-y-1/2'
  };

  const arrowDirection = {
    top: `bottom-full ${alignmentArrow} ${type === 'Delete' ? 'left-5' : (type === 'Duplicate' || type === 'Preview') ? 'left-6' : type === 'Edit' ? 'left-3' : 'left-10.5'} mx-auto transform rotate-180`,
    left: 'right-full -mr-1.25 top-1/2 transform -translate-y-1/2 transform rotate-90',
    bottom: `top-full ${alignmentArrow} right-0 mx-auto`,
    right: 'left-full -ml-1 top-1/2 transform -translate-y-1/2 transform -rotate-90'
  };

  const tooltipBorder = {
    top: 'top-0 left-0 w-full transform rotate-180',
    right: 'w-8 transform -rotate-90 -right-3.25 top-1/2 -translate-y-1/2',
    bottom: 'bottom-0 left-0 w-full',
    left: 'w-8 transform rotate-90 -left-3.25 top-1/2 -translate-y-1/2'
  };

  const tooltipBorderPosition = {
    top: ' border-t-0 ',
    right: 'border-r-0',
    bottom: 'border-b-0',
    left: 'border-l-0'
  };

  return (
    <div className={`absolute border border-solid border-neutral-10 dark:border-neutral-80  backdrop-blur-xs ${tooltipBorderPosition[arrowDirections]} ${direction[directions]}  ${type === 'play' && 'transform -translate-y-1/2 left-1/2 -translate-x-1/2 '} z-[100] rounded-lg ${className2} `} >
      <div className={''}>
        <div className={`absolute  z-[100] w-min  ${arrowDirection[arrowDirections]}`}>
          <div className="triangle transform rotate-180 m-auto w-4 h-1.5 bg-white dark:bg-ToolTip"></div>
          <span className="w-0.25 h-full block absolute left-1 ml-auto bg-white dark:bg-neutral-80 top-0 transform skew-x-49"></span>
          <span className="w-0.25 h-full block absolute right-1 mr-auto bg-white dark:bg-neutral-80 top-0 transform -skew-x-49"></span>
        </div>
        <div className={`absolute flex justify-between  ${tooltipBorder[arrowDirections]}`}>
          <span className={`border-neutral-10 dark:border-neutral-80 h-1.5 block  ${type === 'TM' ? 'TM-border-left'
            : type === 'poll' ? 'poll-borderRight' : 'tooltip-border'} border-b rounded-bl-md bg-transparent `}> </span>
          <span className={`border-neutral-10 dark:border-neutral-80 h-1.5 block border-b rounded-br-md   ${type === 'TM' ? 'TM-border-right' : type === 'poll' ? 'poll-borderLeft' : 'tooltip-border'}  bg-transparent `}>  </span>
        </div>
        <span className={`${className1}`}>{children}</span>
      </div>
    </div >
  );
};

export default Tooltip;
