/* eslint-disable no-param-reassign */
import Image from '@atoms/Image';
import { useEffect, useState } from 'react';

const LinkLibraryPlatform = ({
  index,
  filters,
  element,
  getTextColor,
  setSelectedFilter,
  selectedPlatformIcons
}) => {
  const [hoverState, setHoverState] = useState(false);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (filters?.linkType) setFilter(filters.linkType);
    else setFilter(filters);
  }, [filters]);

  const isNamePrefixMatch = filters !== 'email'
    ? (element.name.replace(' ', ''))?.toLowerCase() === filter?.toLowerCase().replace(' ', '')
    : element.name === 'Email Id';

  const getImage = () => {
    if (filters.linkType) {
      return selectedPlatformIcons.filter((platform) => platform.name === element.name)[0].img;
    }
    if (hoverState) {
      return selectedPlatformIcons.filter((platform) => platform.name === element.name)[0].img;
    }
    return element.img;
  };

  return <>
    <div
      tabIndex={2}
      onClick={() => setSelectedFilter({ linkType: element.name }, true)}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
      key={index}
      className={`flex items-center gap-2 cursor-pointer px-1 w-fit ${isNamePrefixMatch && 'bg-neutral-80 shadow-linkLibrary rounded-lg w-30 py-1'}`}>
      <Image
        src={getImage()}
        // eslint-disable-next-line no-nested-ternary
        className={`flex items-center ${hoverState || isNamePrefixMatch ? 'grayscale-0' : 'grayscale'}`} />

      <p className={`${getTextColor(filters.linkType, element.name, hoverState)} font-Poppins font-semibold text-base leading-3.5 cursor-pointer flex items-center hover:text-white pr-1`}>
        {element.name}
      </p>
    </div>
  </>;
};

export default LinkLibraryPlatform;
