import { memo } from 'react';
import {
  type InteractionColors,
  type InteractionOptions,
} from '@components/Presentation/types';
import OptionOrderType from './optionOrderType';
import { CheckRounded, ClearRounded } from '@mui/icons-material';
import useFonts from '@lib/hooks/useFonts';
import { computeLuminance } from '@lib/interactionHelper';

interface IOptions extends InteractionOptions {
  contribution?: string;
  count?: string;
}

const MultipleChoiceOptions = ({
  option,
  settings,
  index,
  relativeMax,
  showAnswer,
  color,
  totalOptionCount = 2,
  showResponses = true,
}: {
  option: IOptions;
  settings: any;
  index: number;
  relativeMax: number;
  showAnswer: boolean;
  color: InteractionColors;
  totalOptionCount?: number;
  showResponses?: boolean;
}) => {
  const contribution = !showResponses ? 0 : parseInt(option?.contribution || '0', 10);
  const count = !showResponses ? 0 : parseInt(option?.count || '0', 10);

  let percent = 0;
  if (relativeMax && !isNaN(contribution)) {
    percent = (contribution / relativeMax) * 100;
  }

  const width = !showResponses ? '0%' : `${percent}%`;

  const contributionText =
    settings?.DisplayStyle === 'percentage'
      ? `${contribution}%`
      : `${count} votes`;

  const { getFontStyle } = useFonts();
  const fontStyle = getFontStyle(settings.FontStyle || 'Sans-Serif');

  const { isBgLight } = computeLuminance(color?.multipleChoiceBgColor as any || '#111111');

  return (
    <div className="flex items-center gap-3 max-h-40 justify-center py-2 h-full z-[2]">
      <div
        className={`transition-all duration-500 ease-linear ${
          showAnswer ? 'max-w-16' : 'max-w-0 opacity-0'
        } overflow-hidden`}
      >
        {option.checked ? (
          <CheckRounded className={`${isBgLight ? 'text-black' : 'text-white'}`} style={{ fontSize: '3rem' }} />
        ) : (
          <ClearRounded
            className={`${isBgLight ? 'text-black' : 'text-white'} opacity-50`}
            style={{ fontSize: '3rem' }}
          />
        )}
      </div>
      <OptionOrderType
        index={index}
        prefix={settings.prefix}
        type={settings.optionOrderType}
      />

      <div className="h-full flex w-full items-center relative">
        <div
          className="bg-[#E8634D] absolute rounded-md h-full min-w-2 items-center flex transition-all ease-linear duration-1000"
          style={{
            width,
            backgroundColor: color?.multipleChoiceChartColor || '#E8634D',
          }}
        ></div>

        <p
          style={{
            ...fontStyle,
            color: color?.multipleChoiceLabelColor || '#FFF',
          }}
          className="multipleChoiceOptionText z-10 flex flex-grow overflow-hidden  text-ellipsis whitespace-normal px-4 sm:px-8 w-full font-Inter 2xl:text-4xl xl:text-3xl text-3xl text-white font-semibold self-center text-wrap"
        >
          {option.body}
          <span className="ml-4 sm:ml-8">{contributionText}</span>
        </p>
      </div>
    </div>
  );
};

export default memo(MultipleChoiceOptions);
