import { getStream, getUserDetails } from '@services/youtube-platform.service';
import { useState, useEffect, useContext } from 'react';
import { TeamsDataContext } from '@components/context/TeamsContext';
import { StreamDataContext } from '@components/context/StreamContext';

const useChromaData = (streamId) => {
  const { isCreateOrEditPresentation = true } = useContext(StreamDataContext);
  const [chromaData, setChromaData] = useState({ isChroma: false, chromaColor: '#00B140' });
  const { teamContext } = useContext(TeamsDataContext);

  const getData = async () => {
    try {
      let isChroma = false; let chromaColor = '#00B140';

      if (isCreateOrEditPresentation) {
        const { entity: { settings } } = await getStream(streamId);
        ({ is_chroma: isChroma, chroma_color: chromaColor } = settings);
      } else if (teamContext?.oid) {
        const { entity: { settings } } = await getUserDetails(teamContext?.oid);
        ({ linksChromaKeyEnabled: isChroma, linksChromaKeyColor: chromaColor } = settings);
      }

      setChromaData({ isChroma, chromaColor });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, [streamId, teamContext?.oid]);

  return chromaData;
};

export default useChromaData;
