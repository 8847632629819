/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Card from '@atoms/Card';
import Modal from '@molecules/Modal';
import Heading from '@atoms/Heading';
import Button from '@atoms/Button';
import Image from '@atoms/Image';
import Toast from '@molecules/Toast';
import { getCorrectedFilter, maskFilter } from '@lib/link-library-helper';

const ShareModel = ({
  selectedFilter, setShowShareModel, streamId, isResult, streamName
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const maskedFilter = maskFilter(getCorrectedFilter(selectedFilter).replace(/ +/g, ''));

  const handleCopy = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(`${process.env.NEXT_PUBLIC_URL}streams/${streamId}/linkLibrary?skip=0&filter=${maskedFilter}`);
  };

  return <Modal className="flex flex-col items-center" >
    <Card id="ShareModel" bgColor={'bg-white dark:bg-black'} className={'w-full max-w-155 mx-auto mt-16 relative pt-14 px-14 mb-10'}>
      <div onClick={() => setShowShareModel(false)} className='border-focused focus:rounded-lg absolute right-1 top-3.5 w-12 py-2 cursor-pointer primaryActiveBtn'>
        <Image src={isResult ? '/images/cross-icon-black.svg' : '/images/cross-icon.svg'} alt={'cross-icons'} className="w-3 h-3 mx-auto cursor-pointer" />
      </div>
      {isCopied && <Toast severity='success' body='Link copied to clipboard' />}
      <Heading className={` ${isCopied && 'pt-7'} text-2xl text-center leading-8 font-medium ${isResult ? 'text-neutral-90' : 'text-white'}`}>{`Share ${streamName.split(' ').length < 10 ? streamName : `${streamName.split(' ').slice(0, 14).join(' ')} ...`} - ${selectedFilter} Link Library`}</Heading>
      <div className="flex justify-center py-10 gap-6">
        <Button
          onClick={() => setShowShareModel(false)}
          className="text-center text-white py-3.5 px-7 bg-gray-30 rounded-lg font-medium text-sm leading-3.5">Done</Button>
        <Button disabled={isCopied} onClick={handleCopy} className={`flex flex-col px-4.5 py-3.5 items-center ${isResult ? (isCopied ? 'bg-neutral-5' : 'bg-gray-110') : (isCopied ? 'bg-neutral-80' : 'bg-white')} rounded-lg`}>
          <Image src='/images/copy-icon.svg' width={16} height={16} />
          <span className={'mt-0.5 pl-2 text-neutral-90 font-semibold text-sm leading-3.5'}>Copy Link</span>
        </Button>
      </div>
    </Card>
  </Modal>;
};

export default ShareModel;
