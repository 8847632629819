/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Avatar from '@atoms/Avatar';
import Tick from '../public/images/InteractionPage/dynamic-svg/tick.svg';
import Bin from '../public/images/InteractionPage/dynamic-svg/bin.svg';

const QuickQuestionItem = ({
  question,
  index,
  toggleCheckbox,
  currentRosFeature,
  handleDelete,
  isResult,
  showUserName = false,
  interactionType,
  selectedFilter
}) => {
  const [deleteItems, setDeleteItems] = useState(false);
  const [, setValidUrl] = useState(true);

  const getTruncatedName = (str) => {
    if (str.length > 16) return `${str.slice(0, 16)}...`;
    return str;
  };

  const verifyLink = (str) => {
    if (!str.startsWith('http')) {
      return `https://${str}`;
    }
    return str;
  };

  let textColor = '#FFFFFF';

  if (interactionType === 'Quick Questions') {
    textColor = currentRosFeature?.color?.quickQuestionTextColor || '#FFFFFF';
  } else if (interactionType === 'linkLibrary') {
    textColor = currentRosFeature?.color?.linkLibraryTextColor || '#FFFFFF';
  }

  return (
    <div
      onMouseEnter={() => !isResult && setDeleteItems(true)}
      onMouseLeave={() => setDeleteItems(false)}
      className="w-full mt-4 z-[2]">
      <div className={`w-full px-3 py-2.5 xl:pr-0 pr-7 ${(!question.is_checked && !isResult) && 'hover:bg-gray-30 hover:rounded-lg'}`}>
        <div className='flex items-center justify-between '>
          <div className='flex items-center flex-1'>
            <span className={`flex flex-none items-center cursor-default justify-center ${question.is_checked ? 'bg-gray-30 opacity-80' : deleteItems ? 'bg-white text-black' : 'bg-gray-50  text-white'} rounded w-8 h-8 font-Poppins text-base font-medium leading-4 mr-6`}>{Number(index) + 1}</span>

            {(interactionType === 'Quick Questions' || (interactionType === 'linkLibrary' && selectedFilter === 'Email Id'))
              ? <text style={{ color: textColor }} className={`${isResult ? 'w-131 xl:w-150 2xl:w-170' : 'w-131 xl:w-167.5 2xl:w-241'} font-Poppins cursor-default py-0.5
              ${isResult ? 'text-base' : 'text-2xl'} leading-6 font-medium  focus:outline-none line-clamp-1 hover:line-clamp-6
              ${question.is_checked ? 'opacity-60' : 'opacity-100'}
						`}>
                {question?.body?.split(' ').map((word) => (word.length > 52 ? word.replace(/(.{52})/g, '$1-') : word)).join(' ')}
              </text>
              : <div className='flex items-center space-x-2'>
                <img onError={() => setValidUrl(false)} src={question.link_type === 'email' ? '/images/linkLibrary/emailSelect.svg' : '/images/linkLibrary/default_web_icon.png'} className='h-8' alt={'favicon_image'} />

                <a href={verifyLink(question.body)} target="_blank" className={` ${isResult ? 'w-131 xl:w-150 2xl:w-170' : 'w-131 xl:w-167.5 2xl:w-241'} font-Poppins cursor-pointer py-0.5
              ${isResult ? 'text-base' : 'text-2xl'} leading-8 font-medium  focus:outline-none line-clamp-1 hover:line-clamp-6
              ${question.is_checked ? 'opacity-60' : 'opacity-100'}
              `}
                style={{ color: textColor }}
                >
                  {question?.body?.split(' ').map((word) => (word.length > 52 ? word.replace(/(.{52})/g, '$1-') : word)).join(' ')}
                </a>
              </div>
            }
          </div>

          <div className={`flex flex-row justify-between ${showUserName ? 'xl:w-80 w-60' : 'xl:w-21 w-17'}  ml-4`} >
            {showUserName && <div className={`flex flex-row items-center rounded ${question.is_checked ? 'bg-gray-30 text-neutral-95' : 'bg-gray-40  text-white'} }`}>
              {(question.platform_type !== 'youtube' || !question?.raw_payload?.profileUrl) ? <Avatar className='ml-1 flex-0' textClass='text-xs ' bgClass="rounded-md" name={question?.username} w='6' h='6' />
                : <img
                  className='ml-1 w-6 h-6 rounded'
                  src={question?.raw_payload?.profileUrl}
                  alt="profile-picture"
                />}
              <text className='max-w-36 whitespace-nowrap font-medium text-xs leading-3  py-2.5 px-3'>{getTruncatedName(question.username)}</text>
            </div>}
            {
              (deleteItems && !question.is_checked) && <div className='flex  xl:ml-0 ml-3'>
                <div className='w-10 h-8 mr-1 cursor-pointer' onClick={() => handleDelete(index, true)}>
                  <Bin className={'fill-current text-white self-center group-hover:block cursor-pointer m-auto'} width="14" height="30" />
                </div>
                {
                  interactionType !== 'linkLibrary' && (
                    <div className='w-10 h-8 cursor-pointer' onClick={() => toggleCheckbox(index)}>
                      <Tick className={` fill-current cursor-pointer  ${question.is_checked ? 'text-neutral-95 ' : 'text-white'} m-auto`} width="16" height="30" />
                    </div>
                  )
                }

              </div>

            }
            {
              question.is_checked && <div className={`w-10 h-8 ${showUserName ? 'xl:-mr-0 -mr-7 ' : 'xl:ml-11 ml-17'}  ${!isResult ? 'cursor-pointer' : 'cursor-default'} `} onClick={() => !isResult && toggleCheckbox(index)}>
                <Tick className={`fill-current ${question.is_checked ? 'text-neutral-95 ' : 'text-white'} m-auto`} width="16" height="30" />
              </div>
            }
          </div>
        </div>
      </div>
    </div >
  );
};

export default QuickQuestionItem;
