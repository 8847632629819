import Button from '@atoms/Button';
import Image from '@atoms/Image';
import { useEffect, useState } from 'react';
import LinkLibraryIcon from '../../public/images/InteractionPage/dynamic-svg/link-library.svg';
import UpArrow from '../../public/images/InteractionPage/dynamic-svg/up-arrow.svg';
import DownArrow from '../../public/images/InteractionPage/dynamic-svg/down-arrow.svg';
import LinkLibraryPlatform from './LinkLibraryPlatform';

const selectedPlatformIcons = [
  { name: 'All', img: '/images/linkLibrary/HyperLink.svg' },
  { name: 'Twitch', img: '/images/linkLibrary/twitchSelect.svg' },
  { name: 'LinkedIn', img: '/images/linkLibrary/linkdinSelect.svg' },
  { name: 'Facebook', img: '/images/linkLibrary/fbSelect.svg' },
  { name: 'Twitter', img: '/images/linkLibrary/twitterSelect.svg' },
  { name: 'Amazon', img: '/images/linkLibrary/amazonSelect.svg' },
  { name: 'YouTube', img: '/images/linkLibrary/youtubeSelect.svg' },
  { name: 'Spotify', img: '/images/linkLibrary/spotifySelect.svg' },
  { name: 'Email Id', img: '/images/linkLibrary/emailSelect.svg' },
  { name: 'Instagram', img: '/images/linkLibrary/instagramSelect.svg' }
];

const filterMap = {
  all: { name: 'All', img: '/images/linkLibrary/HyperLink.svg' },
  twitch: { name: 'Twitch', img: '/images/linkLibrary/twitchSelect.svg' },
  linkedin: { name: 'LinkedIn', img: '/images/linkLibrary/linkdinSelect.svg' },
  facebook: { name: 'Facebook', img: '/images/linkLibrary/fbSelect.svg' },
  twitter: { name: 'Twitter', img: '/images/linkLibrary/twitterSelect.svg' },
  amazon: { name: 'Amazon', img: '/images/linkLibrary/amazonSelect.svg' },
  youtube: { name: 'YouTube', img: '/images/linkLibrary/youtubeSelect.svg' },
  spotify: { name: 'Spotify', img: '/images/linkLibrary/spotifySelect.svg' },
  email: { name: 'Email Id', img: '/images/linkLibrary/emailSelect.svg' },
  emailid: { name: 'Email Id', img: '/images/linkLibrary/emailSelect.svg' },
  instagram: { name: 'Instagram', img: '/images/linkLibrary/instagramSelect.svg' }
};

const LinkLibraryDropdown = ({
  handleSelectedFilter,
  setShowShareModel = () => { },
  filters,
  showDropdown,
  setShowDropdown,
  showShareButton = true,
  selectedFilterName
}) => {
  const getTextColor = (currentSelectedFilter, element, hoverState) => {
    if (hoverState) {
      return 'text-white';
    }
    if (currentSelectedFilter === element.name) {
      return 'text-white';
    }

    return 'text-neutral-50';
  };

  const [processedFilterType, setProcessedFilterType] = useState('all');
  useEffect(() => {
    let objName = '';
    // eslint-disable-next-line no-unsafe-optional-chaining
    if (selectedFilterName === undefined) { objName = ((filters?.linkType).toLowerCase()).replace(/\s+/g, ''); } else {
      objName = ((selectedFilterName).toLowerCase()).replace(/\s+/g, '');
    }

    setProcessedFilterType(objName);
  }, [filters?.linkType, selectedFilterName]);

  return (
    <>
      <div className='flex justify-between'>
        <div className='flex relative'>
          <Button tabIndex={1} onClick={() => setShowDropdown(!showDropdown)} className='flex flex-col px-5 py-3 items-center bg-neutral-80 rounded-lg '>

            {
              selectedFilterName === 'all' ? <LinkLibraryIcon width="16" height="16" className=" fill-white" />
                : <img src={filterMap[processedFilterType]?.img} />
            }

            <span className='mt-0.5 pl-2 pr-5 text-neutral-50 font-semibold text-sm leading-3.5 hover:text-white'>{`${filterMap[processedFilterType]?.name}`}</span>
            {showDropdown ? <UpArrow width="17" height="17" className=" fill-white" /> : <DownArrow width="17" height="17" className=" fill-white" />}
          </Button>
          <div id='LinkTypeDropDown' className={`bg-gray-30 ${!showDropdown && 'hidden'} w-79 h-60 absolute top-12 left-0 rounded-lg px-3 py-3 grid grid-cols-2 gap-5`}>
            {selectedPlatformIcons.map((element, index) => <LinkLibraryPlatform
              key={element.name}
              element={element} index={index}
              filters={selectedFilterName || filters}
              setSelectedFilter={handleSelectedFilter}
              setShowDropdown={setShowDropdown}
              selectedPlatformIcons={selectedPlatformIcons}
              getTextColor={getTextColor} />)}
          </div>
        </div>
        {showShareButton && <div >
          <Button tabIndex={4} onClick={() => setShowShareModel(true)} className='flex flex-col p-3 items-center bg-white rounded-lg'>
            <Image src='/images/share.svg' width={17} height={17} />
            <span className='mt-0.5 pl-2 text-neutral-90 font-semibold text-sm leading-3.5'>Share</span>
          </Button>
        </div>}
      </div>

    </>
  );
};

export default LinkLibraryDropdown;
