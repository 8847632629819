const filterMap = {
  All: '07350a14-a07e-4814-9d12-2f743b6957bf',
  Twitter: 'c6a0f5e6-1e0e-4f3b-8f2d-8d4e7f4b4f1e',
  Youtube: 'ee13697b-d38e-48e9-85c9-36d19e610361',
  Facebook: 'a2e0f0e6-5a7b-4e5f-9e5b-9d1e0e8f7b5a',
  Twitch: 'b6c7d8e9-faeb-4d5c-8b9a-7a6b5c4d3e2f',
  Linkedin: 'c1b2a3d4-e5f6-7a8b-9c1d-2e3f4a5b6c7d',
  Amazon: 'd1e2f3a4-b5c6-7d8e-9f1a-2b3c4d5e6f7a',
  Spotify: 'e1f2a3b4-c5d6-e7f8-9a1b-2c3d4e5f6a7b',
  email: 'f1a2b3c4-d5e6-f7a8-b9c1-2d3e4f5a6b7c',
  Instagram: '90973e4d-1da8-401a-af7d-103f061c5b04'
};

const maskFilter = (link) => filterMap[link] || link;

const unmaskFilter = (link) => Object.entries(filterMap).find(([, v]) => v === link)?.[0] || link;

const getCorrectedFilter = (filter) => {
  if (filter === 'Email Id') return 'email';
  if (filter === 'Linked In') return 'Linkedin';
  return filter;
};

const getUniqueLinks = (allLinks, strBody, deletedLinks = []) => allLinks
  .map((link) => link[strBody])
  .map((link, index, linksArray) => linksArray.indexOf(link) === index && index)
  .filter((link) => allLinks[link]).filter((link) => !deletedLinks.includes(allLinks[link]))
  .map((link) => allLinks[link]);

const processDeletedLinks = (allLinks, groupedLinks = {}, deleteLinkLibrary) => allLinks.filter((link) => {
  const url = link.body.toLowerCase();
  if (groupedLinks[url]) deleteLinkLibrary(link.id);
  return !groupedLinks[url];
}
);
export {
  getCorrectedFilter, maskFilter, unmaskFilter, getUniqueLinks, processDeletedLinks
};
