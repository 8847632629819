import React, {
  useEffect, useRef, useState, forwardRef, useImperativeHandle, useCallback, useContext
} from 'react';
import router, { useRouter } from 'next/router';
import debounce from 'lodash.debounce';

import { updateDoc, arrayRemove } from 'firebase/firestore';

import useModerationUpdate from '@lib/hooks/useModerationUpdate';

import { TeamsDataContext } from '@components/context/TeamsContext';

import { updateQuickQuestion } from '@services/quick-questions-service';
import Image from '@atoms/Image';
import Loader from '@atoms/Atom/Loader';

import QuickQuestionItem from '@molecules/QuickQuestionItem';
import { updateEngageType, getQuickQuestionsPaginated, updateLivestreamSession } from '@services/youtube-platform.service';
import { StreamDataContext } from '@components/context/StreamContext';
import useLoadingTimeout from '@lib/hooks/useLoadingTimeout';
import { getAuth } from '@services/identity.service';
import useNotificationTimer from '@lib/hooks/useNotificationTimer ';
import NextImage from 'next/image';

const QuickQuestions = forwardRef(({
  streamId,
  totalQuestions,
  isResult,
  questionsData,
  moderationMode = false,
  platformType,
  currentRosFeature,
  colorData,
  setShowNotification,
  isOverview = false,
  isSandbox = false,
  livestreamData
}, ref) => {
  const [query, setQuery] = useState('');
  const [questionCount, _setQuestionCount] = useState(totalQuestions);
  const [seconds, setSeconds] = useState(0);
  const [isLoading, setIsLoading] = useLoadingTimeout(30000);
  const isFirstCallRef = useRef(true);
  const [atBottom, setAtBottom] = useState(false);
  const { user } = useContext(TeamsDataContext);
  const [fetchingChats, setFetchingChats] = useState(false);
  const [counter, setCounter] = useState(0);
  const [settings, setSettings] = useState({});

  const scrollContainerRef = useRef(null);
  const previousScrollTop = useRef(0);

  const {
    setQuestionPage
  } = useContext(StreamDataContext);

  const joinType = useRouter().query?.joinType;
  const auth = getAuth();

  const getUniqueQuestions = (allQuestions, strBody) => allQuestions
    .map((question) => question[strBody])
    .map((question, index, questionsArray) => questionsArray.indexOf(question) === index && index)
    .filter((question) => allQuestions[question]).map((question) => allQuestions[question]);

  const [quickQuestions, _setQuickQuestions] = useState(getUniqueQuestions([], 'body') || []);
  const [uniqueQuickQnsLength, _setUniqueQuickQnsLength] = useState(quickQuestions?.length);
  const uniqueQuickQnsLengthRef = useRef(uniqueQuickQnsLength);
  const quickQuestionsRef = useRef(quickQuestions);
  const questionCountRef = useRef(questionCount);
  const secondsRef = useRef(0);

  useNotificationTimer(currentRosFeature, quickQuestions, setShowNotification);

  const setQuickQuestions = (data) => {
    quickQuestionsRef.current = data;
    _setQuickQuestions(data);
    setQuestionPage(data.length / 2);
  };

  const setQuestionCount = (data) => {
    questionCountRef.current = data;
    _setQuestionCount(data);
  };

  const setUniqueQuickQnsLength = (data) => {
    uniqueQuickQnsLengthRef.current = data;
    _setUniqueQuickQnsLength(data);
  };

  const fetchMoreData = async () => {
    if (isResult && questionsData?.length > 0) {
      _setQuickQuestions(questionsData);
      return;
    }
    setFetchingChats(true);
    const page = Math.floor(quickQuestionsRef.current.length / 10) * 10;
    try {
      const response = await getQuickQuestionsPaginated(
        streamId,
        { skip: page, search: query },
        auth
      );
      if (response.status) setFetchingChats(false);
      if (response?.status && response.entity.rows.length > 0) {
        const uniqueQuestions = getUniqueQuestions([...quickQuestionsRef.current, ...response.entity.rows], 'body');
        setQuickQuestions(uniqueQuestions);
        setUniqueQuickQnsLength(uniqueQuestions.length);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to search fetch questions', error);
      setFetchingChats(false);
    }
  };

  const toggleCheckbox = async (index) => {
    const updatedQns = [...quickQuestions];
    updatedQns[index].is_checked = !updatedQns[index].is_checked;
    await updateQuickQuestion({
      body: updatedQns[index].body,
      isChecked: updatedQns[index].is_checked,
      isDeleted: false,
      streamId
    });
    setQuickQuestions(updatedQns);
    setUniqueQuickQnsLength(updatedQns.length);
  };

  const handleDelete = async (index) => {
    if (questionsData?.length > 0) return;

    const questions = [...quickQuestions];
    const deleteRes = await updateQuickQuestion({
      body: questions[index].body,
      isChecked: questions[index].is_checked,
      isDeleted: true,
      streamId
    });

    if (deleteRes.status && deleteRes.entity) {
      const updatedList = questions.filter((x) => x.id !== questions[index].id);
      setQuestionCount(questionCount - 1);
      setQuickQuestions(updatedList);
      setUniqueQuickQnsLength(updatedList.length);
    }
  };

  const getQuickQuestions = async (q) => {
    if (questionsData?.length > 0) return;

    if (isFirstCallRef.current) {
      setIsLoading(true);
      setFetchingChats(true);
    }
    try {
      const response = await getQuickQuestionsPaginated(
        streamId,
        { skip: 0, sort: true, search: q },
        auth
      );

      if (response.status) setFetchingChats(false);
      if (response.status && response?.entity?.rows?.length > 0) {
        const uniqueQuestions = getUniqueQuestions([...quickQuestions, ...response.entity.rows], 'body');
        setQuickQuestions(uniqueQuestions);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to search get quick questions', error);
      setFetchingChats(false);
    }
    if (isFirstCallRef.current) {
      setIsLoading(false);
      isFirstCallRef.current = false;
    }
  };

  const reset = () => {
    setQuery('');
    getQuickQuestions(query);
  };

  const search = async (q) => {
    setFetchingChats(true);
    try {
      const response = await getQuickQuestionsPaginated(
        streamId,
        { skip: 0, sort: true, search: q },
        auth
      );

      if (response.status) setFetchingChats(false);

      if (response.status && response.entity.rows.length > 0) {
        setQuickQuestions(getUniqueQuestions([...response.entity.rows, ...response.entity.rows], 'body'));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to search quick questions', error);
      setFetchingChats(false);
    }
  };

  const debouncedSearch = useCallback(debounce(search, 500, { trailing: true }), []);

  const handleQuery = (evt) => {
    setQuery(evt.currentTarget.value);
    if (evt.currentTarget.value === '') {
      return;
    }
    debouncedSearch(evt.currentTarget.value);
  };

  useEffect(() => {
    if (questionsData?.length > 0) return;
    getQuickQuestions(query);
  }, []);

  useEffect(() => {
    if (questionsData?.length > 0) return;
    if (isResult || moderationMode) return;
    const sessionId = localStorage.getItem('sessionId');
    updateEngageType(streamId, 'Quick Questions', { platformType });
    if (sessionId) updateLivestreamSession(sessionId, { engageType: 'Quick Questions', updateQuickQuestionCount: true });
  }, []);

  useEffect(() => {
    if (questionsData?.length > 0) return;

    const interval = setInterval(() => {
      if (uniqueQuickQnsLengthRef.current < 10) {
        setSeconds(seconds + 1);
        let q;
        setQuery((prev) => { q = prev; return prev; });
        getQuickQuestions(q);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (questionsData?.length > 0) return;

    const interval = setInterval(() => {
      if (!atBottom) {
        clearInterval(interval);
        setCounter(0);
      } else {
        setCounter(counter + 1);
        if (counter === 0) return;
        fetchMoreData();
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [counter, atBottom]);

  const stopCurrentFeature = () => {
    router.push({ pathname: `/streams/${streamId}/ros`, query: joinType && { joinType } });
  };

  useImperativeHandle(ref, () => ({ stopCurrentFeature }));

  const handleFireStoreUpdate = (docRef, fsData) => {
    if (questionsData?.length > 0) return;

    if (!quickQuestionsRef.current || !questionCountRef.current) return;

    let updates = [];
    if (fsData.quickQuestions && fsData.quickQuestions.length > 0) {
      updates = fsData.quickQuestions;
    }

    const tmpQuestions = [...quickQuestionsRef.current];

    if (tmpQuestions.length === 0) {
      return;
    }

    let tmpCount = questionCountRef.current.length;

    for (let i = 0; i < updates.length; i += 1) {
      const update = updates[i];
      const idx = tmpQuestions.findIndex((x) => x.body === update.body);
      if (idx > -1) {
        if (update.isDeleted) {
          tmpQuestions.splice(idx, 1);
          tmpCount -= 1;
        } else {
          tmpQuestions[idx].is_checked = update.isChecked;
        }
        updateDoc(docRef, {
          quickQuestions: arrayRemove(update)
        });
      }
    }
    setQuestionCount(tmpCount);
    setQuickQuestions(tmpQuestions);
    setUniqueQuickQnsLength(tmpQuestions.length);
  };

  useModerationUpdate(user?.id, streamId, handleFireStoreUpdate, moderationMode, isResult);

  const getStyle = () => {
    const style = { height: 'calc(100vh - 218px)' };
    style.cursor = 'default';
    if (moderationMode) {
      style.height = 'calc(100vh - 266px)'; // + 48px height of Search
    } else if (isSandbox) {
      style.height = 'calc(100vh - 400px)';
    } else if (isResult) {
      delete style.height;
    }
    return style;
  };

  const handleScroll = () => {
    const { scrollTop } = scrollContainerRef.current;

    if (scrollTop < previousScrollTop.current) {
      setAtBottom(false);
      return;
    }

    previousScrollTop.current = scrollTop;
    fetchMoreData();
    setAtBottom(true);
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    scrollContainer.addEventListener('scroll', handleScroll);
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    handleScroll();
  }, [quickQuestions?.length]);

  const bgImg = livestreamData?.settings?.quickQuestionImageSrc;
  const bgImgOpacity = livestreamData?.settings?.quickQuestionImageOpacity / 100;
  let bgColor;
  let textColor;
  if (isResult) {
    bgColor = livestreamData?.colors?.quickQuestionBgColor || '#111111';
    textColor = livestreamData?.colors?.quickQuestionTextColor || '#ffffff';
  }

  useEffect(() => {
    if (!currentRosFeature) return;
    setSettings(currentRosFeature.setting);
  }, [currentRosFeature]);

  return (
    <>
      <div className='h-full w-full' >
        {moderationMode && (
          <div className='relative flex items-center justify-between bg-gray-30 rounded-lg'>
            <Image src="/images/Search1.svg" className='ml-5' />
            <input
              placeholder="Search Entries"
              value={query}
              onChange={handleQuery}
              type="text"
              autoComplete='off'
              className={'w-full bg-gray-30 text-neutral-50 text-xs leading-3 font-normal pl-3 border border-gray-30 outline-none rounded-lg py-3.5'}
              name="search" />
            <button onClick={reset}>
              <Image src="/images/reload.svg" className='mr-5' />
            </button>
          </div>
        )}

        <div
          id={`${isResult ? '' : 'scrollableDiv11'}`}
          style={{ ...getStyle(), backgroundColor: bgColor, color: textColor }}
          className={`px-5
          scrollable-container flex flex-col pb-5 ${isResult && 'h-116'} no-scrollbar relative`}
          ref={scrollContainerRef}
        >
          {bgImg && <NextImage
            style={{ opacity: bgImgOpacity }}
            src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${bgImg}`}
            className=' object-cover rounded-lg'
            fill
            alt='background image'
          />}
          {isLoading ? <Loader showMessages={false} />
            : <> {quickQuestions?.length !== 0 ? quickQuestions?.map((question, index) => (
              <QuickQuestionItem key={question.id} index={index} question={question}
                toggleCheckbox={toggleCheckbox}
                currentRosFeature={currentRosFeature}
                handleDelete={handleDelete} isResult={isResult}
                showUserName={settings?.showUserName} interactionType={'Quick Questions'} />
            ))
              : <div className="absolute m-0 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-10">
                <div className='flex flex-col items-center'>
                  <Image src='/images/chat.svg' />
                  <div className='mt-8'>
                    <div className='flex justify-center items-center'>
                      <span className='font-medium	text-white text-center text-lg leading-6'>Nobody has added</span><br />
                    </div>
                    <div className='flex justify-center items-center'>
                      <span className='font-medium	text-white text-center text-lg leading-6'>a question yet in the chat</span>
                    </div>
                  </div>
                </div>
              </div>
            }
            </>}
        </div>
        {fetchingChats && !isLoading
          && <div className='absolute bottom-2 left-[45%]'>
            <Loader showMessages={false} />
          </div>
        }
      </div >
    </>
  );
});

export default QuickQuestions;
