import { StreamDataContext } from '@components/context/StreamContext';
import { pushDataLayerForEvent } from '@lib/gtag';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { hexToRGB } from '@services/utils.service';

import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import useChromaData from './helper/useChroma';

const MapBoxComponent = ({
  streamId,
  mapId,
  interactionState,
  colorData,
  mapData,
}) => {
  mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAPBOX_TOKEN;
  const mapContainerRef = useRef(null);
  const map = useRef(null);
  const [point, setPoint] = useState([]);
  const pointsRef = useRef([]);
  const newPointsRef = useRef([]);
  const viewRegion = { center: [29, 9.88], zoom: 1.3 };
  const mapHeight = mapData?.height ? mapData.height : '100%';

  const {
    mapBox,
    featureSettings
  } = useContext(StreamDataContext);

  const { oneUoneV } = featureSettings;

  const chromaData = useChromaData(streamId);

  const cleanUp = () => {
    const mapInstance = map.current;

    setPoint([]);
    pointsRef.current = [];
    newPointsRef.current = [];

    if (mapInstance.getSource('newPoint')) {
      mapInstance.getSource('newPoint').setData({
        type: 'FeatureCollection',
        features: []
      });
    }
    if (mapInstance.getSource('point')) {
      mapInstance.getSource('point').setData({
        type: 'FeatureCollection',
        features: []
      });
    }
  };

  const createMap = (colors) => {
    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: process.env.NEXT_PUBLIC_MAPBOX_STYLES,
      center: viewRegion.center,
      zoom: viewRegion.zoom,
      testMode: true // need to make this configurable
    });

    const mapInstance = map.current;

    mapInstance.on('load', async () => {
      try {
        mapInstance.setPaintProperty('water', 'fill-color', colors.mapWaterColor);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Map.js load waterColor style has error!', err);
      }
      try {
        mapInstance.setPaintProperty('land', 'background-color', colors.mapLandColor);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Map.js load landColor style has error!', err);
      }

      mapInstance.addSource('point', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: mapData?.features || []
        },
        cluster: true,
        clusterMaxZoom: 4,
        clusterRadius: 50,
        tolerance: 0
      });

      mapInstance.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'point',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            `${hexToRGB(colors.smallCircleColor, 0.4)}`,
            50,
            `${hexToRGB(colors.midCircleColor, 0.4)}`,
            100,
            `${hexToRGB(colors.bigCircleColor, 0.4)}`
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            50,
            30,
            100,
            40
          ]
        }
      });

      mapInstance.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'point',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      mapInstance.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'point',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': colors.individualPointColor,
          'circle-radius': 6,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });

      mapInstance.addLayer({
        id: 'points',
        type: 'symbol',
        source: 'point'
      });

      mapInstance.addSource('newPoint', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });

      mapInstance.addLayer({
        id: 'newpoints',
        type: 'circle',
        source: 'newPoint',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': colors.latestPointColor,
          'circle-radius': 10,
          'circle-stroke-width': 8,
          'circle-stroke-color': `${hexToRGB(colors.latestPointColor, 0.4)}`
        }
      });

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        className: 'mapbox-popup'
      });

      mapInstance.on('mouseenter', 'newpoints', (e) => {
        mapInstance.getCanvas().style.cursor = 'pointer';
        const count = ((e.features.length / 2) - 1) > 10 ? 10 : ((e.features.length / 2) - 1);

        const feature = e.features[0];
        const coordinates = feature.geometry.coordinates.slice();

        const htmlStr = `
                <div class="w-auto">
                <p class="font-semibold text-neutral-110 text-base pl-1 pb-2">${feature.properties.place}</p>`
          + `<p class="pl-1 text-sm text-neutral-110">${feature.properties.username} ${((e.features.length / 2) > 1) ? `+${count} more` : ''}</p></div>`;

        popup.setLngLat(coordinates).setHTML(htmlStr).addTo(mapInstance);
      });

      mapInstance.on('mouseleave', 'points', () => {
        mapInstance.getCanvas().style.cursor = '';
        popup.remove();
      });

      mapInstance.on('mouseleave', 'newpoints', () => {
        mapInstance.getCanvas().style.cursor = '';
        popup.remove();
      });

      mapInstance.on('click', 'clusters', (e) => {
        const features = mapInstance.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        const clusterId = features[0].properties.cluster_id;
        mapInstance.getSource('point').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            mapInstance.easeTo({
              center: features[0].geometry.coordinates,
              zoom
            });
          }
        );
      });

      mapInstance.on('mouseenter', 'unclustered-point', (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const feature = e.features[0];
        const count = ((e.features.length / 2) - 1) > 10 ? 10 : ((e.features.length / 2) - 1);
        const htmlStr = `
                <div class="w-auto">
                <p class="font-semibold text-neutral-110 text-base pl-1 pb-2">${feature.properties.place}</p>`
          + `<p class="pl-1 text-sm text-neutral-110">${feature.properties.username} ${((e.features.length / 2) > 1) ? `+${count} more` : ''}</p></div>`;

        popup.setLngLat(coordinates).setHTML(htmlStr).addTo(mapInstance);
      });

      mapInstance.on('mouseleave', 'unclustered-point', () => {
        mapInstance.getCanvas().style.cursor = '';
        popup.remove();
      });

      mapInstance.on('mouseenter', 'clusters', () => {
        mapInstance.getCanvas().style.cursor = 'pointer';
      });

      mapInstance.on('mouseleave', 'clusters', () => {
        mapInstance.getCanvas().style.cursor = '';
      });
    });

    mapInstance.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-left');

    return mapInstance;
  };
  const updateMap = (features) => {
    const mapInstance = map.current;

    if (features.length && mapInstance.getSource('newPoint') && mapInstance.getSource('point')) {
      mapInstance.getSource('newPoint').setData({
        type: 'FeatureCollection',
        features: [features[0]]
      });

      mapInstance.getSource('point').setData({
        type: 'FeatureCollection',
        features: oneUoneV ? [...features] : [...pointsRef.current, ...features]
      });

      pointsRef.current = oneUoneV ? [...features] : [...pointsRef.current, ...features];
      newPointsRef.current = features;
      setPoint([...pointsRef.current]);
    }
  };

  const setMap = (features) => {
    try {
      if (features) {
        const mapInstance = map.current;

        if (!mapInstance.getSource('point')) {
          setTimeout(() => {
            setMap(features);
          }, 500);
          return;
        }

        if (features && features.length && mapInstance.getSource('point')) {
          mapInstance.getSource('point').setData({
            type: 'FeatureCollection',
            features
          });

          pointsRef.current = features;
          newPointsRef.current = features;
          setPoint([...pointsRef.current]);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    const mapInstance = createMap(colorData);

    const features = mapData?.features || [];

    pointsRef.current = features;
    newPointsRef.current = features;
    setPoint([...pointsRef.current]);

    return () => {
      mapInstance.remove();
    };
  }, [streamId, mapId, mapData]);

  useEffect(() => {
    if (mapBox.type === 'viewRegion') { map.current.jumpTo(mapBox.viewRegion); return; }
    if (mapBox.type === 'update') { updateMap(mapBox.features); return; }
    if (mapBox.type === 'set') { setMap(mapBox.features); return; }
    if (mapBox.type === 'clean') { cleanUp(); return; }
    cleanUp();
  }, [mapBox, chromaData?.chromaColor]);

  useEffect(() => {
    point?.length > 0 && pushDataLayerForEvent('populate_comments_magic_map');
  }, [point?.length > 0]);

  return <div className={`${interactionState === 'ready' && 'filter blur-lg'}`} style={{ height: mapHeight, borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }} ref={mapContainerRef} />;
};

export default MapBoxComponent;
