import { useEffect, useRef } from 'react';

const useNotificationTimer = (currentRosFeature, comments, setShowNotification) => {
  const secondsRef = useRef(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      secondsRef.current += 1;

      if (comments.length > 0 && secondsRef.current >= 4 && setShowNotification) {
        setShowNotification(false);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [currentRosFeature, comments]);
};

export default useNotificationTimer;
