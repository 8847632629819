import {
  useEffect
} from 'react';

import {
  doc,
  onSnapshot,
  setDoc,
  deleteDoc
} from 'firebase/firestore';
import db from '@services/firebase-service';

const useModerationUpdate = (userId, streamId, callBackFunc, moderationModeRef) => {
  let docRef;
  let unsub;

  useEffect(() => {
    (async () => {
      if (!moderationModeRef.current) return;
      docRef = doc(db, 'streams', streamId, 'moderation', userId);
      await setDoc(docRef, {}, { merge: true });
      unsub = onSnapshot(docRef, (snapRef) => {
        const snapData = snapRef.data();
        callBackFunc(docRef, snapData);
      });
    })();

    return () => {
      if (docRef) deleteDoc(docRef);
      if (unsub) unsub();
    };
  }, []);
};

export default useModerationUpdate;
