import { useState, useEffect } from 'react';

function useLoadingTimeout(timeoutDuration) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let timeoutId;

    if (isLoading) {
      timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, timeoutDuration);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isLoading, timeoutDuration]);

  return [isLoading, setIsLoading];
}

export default useLoadingTimeout;
