import SATooltip from '@molecules/Tooltip';
import React, { forwardRef } from 'react';
import { handleKeyStrokes } from '@services/utils.service';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Tooltip from '@components/Presentation/atoms/Tooltip';

const SvgIconBox = forwardRef(({
  svg, text, className = 'rounded-lg', id, onClick, toolTipText, toolTipPosition = 'right', type, disablePointer = false,
  directions, onMouseEnter, onMouseLeave,
  arrowDirections, alignment, alignmentArrow, tabIndex, className2 = '', toolTipColor = 'text-white', showDarkToolTip = false, place = 'top',
  preview = null
}, ref) => (
  <Tooltip
    content={preview}
    placement='right'
    isDisabled={!preview}
    classNames={{
      base: ['before:bg-transparent dark:before:bg-transparent'],
      content: ['py-2 px-3 rounded-md text-xs', 'bg-red-500 dark:bg-transparent shadow-none text-grey-50'],
    }}
  >
    <div id={id} className="relative group">
      <div className={`${showDarkToolTip && 'container'}`}>
        <div
          data-tooltip-id={`${showDarkToolTip && id}`}
          data-tooltip-content={`${showDarkToolTip && toolTipText}`}
          tabIndex={tabIndex}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onKeyDown={handleKeyStrokes}
          onClick={onClick}
          ref={ref}
          className={`group ${!disablePointer ? 'cursor-pointer' : 'cursor-default'} flex justify-center items-center ${className}`}>
          {svg || text}
        </div>
        {showDarkToolTip ? <ReactTooltip id={id} place={place} className={id} />
          : <div className="hidden group-hover:block ">
            {toolTipText && <SATooltip
              position={toolTipPosition} type={type}
              directions={directions}
              arrowDirections={arrowDirections}
              alignment={alignment}
              alignmentArrow={alignmentArrow}
              className2={` ${(type === 'Delete' || type === 'Duplicate' || type === 'Edit' || type === 'Preview') ? 'py-1.5 px-2.5' : 'p-2'} font-medium text-xs leading-2.5 rounded-lg text-white shadow-comment-overlay bg-ToolTip backdrop-filter w-max ${className2}`}
              className1={`${toolTipColor} border-gray-50 w-max`}>{toolTipText}</SATooltip>}
          </div>}
      </div>
    </div>
  </Tooltip>
));

export default SvgIconBox;
